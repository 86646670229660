<template>
  <div class="main" style="min-height: 80vh;background-color: #eaedf1;">
    <div class="breadmenu" @click="goBack()">
      <i class="el-icon-arrow-left" /> 返回上一页
    </div>
    <div v-loading="paying" class="ssss">
      <div class="content">
        <div style="display: flex;">
          <!-- 左内容 -->
          <div class="image-s">
            <img class="image-i" :src="mainImage" alt="" srcset="">
          </div>
          <!-- 右内容 -->
          <div class="content-right">
            <div class="title">
              {{ productObj.subject }}
            </div>
            <div class="price">
              参考价格: ¥ {{ price }}
            </div>
            <div style="margin-top: 20px;margin-bottom:6px">
              规格:
            </div>
            <div class="sku-group">
              <div v-for="(item,index) in productObj.skuList" :key="index" class="sku" :class="index == chooseSku?'chooseSku':''" @click="handleSku(index, item)">
                <div class="sku-images">
                  <img class="sku-imagei" :src="item.skuimageUrl" alt="" srcset="">
                </div>
              </div>
            </div>
            <div class="line" />
            <div style="margin-bottom:6px">
              已选择:
            </div>
            <div v-if="chooseSpu.attributes">
              <div v-for="(item, index) in chooseSpu.attributes" :key="index" class="attributes">
                <div>{{ item.attributeName }}</div>：
                <div>{{ item.attributeValue }}</div>
              </div>
            </div>
            <div style="margin-bottom:6px">
              数量:
            </div>
            <div class="priduct-num">
              <i class="el-icon-remove-outline" style="font-size:25px;margin-right: 6px;" @click="downNum()" />
              <a-input-number id="inputNumber" v-model="quantity" :min="1" :max="amountOnSale" />
              <i class="el-icon-circle-plus-outline" style="font-size:25px;margin-left: 6px;margin-right: 6px;" @click="addNum()" />

              <div>
                库存: {{ amountOnSale }}
              </div>
            </div>
            <div class="line" />
            <div>
              支付方式:
            </div>
            <a-radio-group v-model="payType">
              <a-radio :style="radioStyle" :value="1">
                微信支付
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                支付宝
              </a-radio>
            </a-radio-group>

            <div class="line" />
            <div style="margin-bottom:6px" class="adress-top">
              <div>
                地址:
              </div>
              <a-button type="link" @click="handleAdd()">
                新增地址
              </a-button>
            </div>
            <div class="adress">
              <div>
                <a-radio-group v-model="addressValue">
                  <div v-for="(aitem,aindex) in addressValueList" :key="aindex + 'a'" class="address-item">
                    <a-radio :style="radioStyle" :value="aitem.id" />
                    <div style="flex:1">
                      <div>{{ aitem.userName +' '+ aitem.telNum }}</div>
                      <div>
                        {{ aitem.provinceName +' '+ aitem.cityName +' '+ aitem.countyName +' '+ aitem.detailInfo }}
                      </div>
                    </div>
                    <div>
                      <a-button type="link" @click="handleEdit(aitem)">
                        编辑
                      </a-button> |
                      <a-button type="link" @click="delUseraddress(aitem.id)">
                        删除
                      </a-button>
                    </div>
                  </div>
                </a-radio-group>
              </div>
            </div>
            <div class="line" style="margin-top:6px" />

            <a-button type="primary" class="buy-btn" @click="handleBuy()">
              购买
            </a-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增地址 -->
    <el-dialog
      style="text-align: left;"
      :title="editAddres?'修改地址' : '新增地址'"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleBeforeClose"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="addAddress" label-width="80px">
          <el-form-item label="名称" prop="userName">
            <el-input v-model="addAddress.userName" />
          </el-form-item>
          <el-form-item label="电话" prop="telNum">
            <el-input v-model="addAddress.telNum" />
          </el-form-item>
          <el-form-item label="地区" prop="diquValue">
            <el-cascader
              v-model="addAddress.diquValue"
              :props="{value:'name',label:'name' }"
              :options="regionOptions"
              popper-class="pop-div-my"
              @change="handleChange"
            />
          </el-form-item>

          <el-form-item label="详细地址" prop="detailInfo">
            <el-input v-model="addAddress.detailInfo" />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="xinz()">{{ editAddres?'修改' : '确定' }}</el-button>
      </span>
    </el-dialog>

    <!-- 微信支付 -->
    <el-dialog
      style="text-align: left;"
      title="微信支付"
      :visible.sync="showQrcode"
      width="50%"
      :before-close="handleBeforeClose"
    >
      <div>
        <div>请扫码支付</div>
        <div class="pay">
          <vue-qr :logo-src="require('../../assets/img/system/wxchat.png')" :text="qrcode" :size="300" :margin="30" :white-margin="true" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showQrcode = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isvalidPhone } from '@/utils/validate'
import vueQr from 'vue-qr'
import { getAlibabaProductDetail, getBrandProductDetailedInformation, getUseraddress, addProductorder, productunifiedOrder, getchinaregion, addUserAddress, delUseraddress, editUserAddress, roundRobinOrder } from '@/api/register'
export default {
  components: {
    vueQr
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电话号码'))
      } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }
    return {
      quantity: 1,
      chooseSku: null,
      productObj: {},
      productType: '',
      mainImage: '',
      price: '',
      addressValue: '',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      addressValueList: [],
      dialogVisible: false,
      addAddress: {
        diquValue: []
      },
      diquValue: [],
      regionOptions: [],
      rules: {
        userName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        telNum: [
          { required: true, trigger: 'blur', validator: validPhone }
        ],
        diquValue: [
          { required: true, message: '请输入地区', trigger: 'blur' }
        ],
        detailInfo: [
          { required: true, message: '请输入详情地区', trigger: 'blur' }
        ]
      },
      chooseSpu: {},
      payType: 1, // 1、微信支付；2、支付宝支付
      qrcode: '',
      showQrcode: false,
      paying: false,
      editAddres: false,
      roundRobinPay: '', // 轮询是否支付成功
      maxtimesetIVal: '',
      maxtime: 300,
      amountOnSale: 0 // 可销售数量
    }
  },
  created() {
    this.productType = this.$route.query.selectType
    const productId = this.$route.query.productId
    if (productId && this.productType) {
      this.getProduct(productId, this.productType)
      this.getUseraddress()
      this.getchinaregion()
    } else {
      this.$message.error('参数错误,请重新选择商品')
    }
  },
  destroyed() {
    clearInterval(this.roundRobinPay) // 清空循环查询
    clearInterval(this.maxtimesetIVal) // 清空倒计时
  },

  methods: {
    handleChange() {

    },

    /** 返回上一页 */
    goBack() {
      this.$router.go(-1)
    },

    handleSku(index, item) {
      this.chooseSku = index
      this.mainImage = item.skuimageUrl
      this.price = item.wholesalePrice
      this.chooseSpu = item
      this.amountOnSale = item.amountOnSale
      console.log('选择', this.chooseSpu)
    },

    addNum() {
      if (this.quantity > 100) {
        return
      }
      this.quantity += 1
    },

    downNum() {
      if (this.quantity < 1) {
        return
      }

      this.quantity -= 1
    },

    // 获取详情
    getProduct(productId, productType) {
      if (productType === '1') {
        getAlibabaProductDetail(productId).then(res => {
          this.productObj = res.data
          this.mainImage = this.productObj.mainImage
        })
      } else {
        getBrandProductDetailedInformation(productId).then(res => {
          this.productObj = res.data
          this.mainImage = this.productObj.mainImage
        })
      }
    },

    // 购买
    handleBuy() {
      if (this.chooseSku === null || !this.addressValue) {
        this.$message.warning('未选择规格或地址')
        return
      }
      const order = {
        'appType': 'PC',
        'deliveryWay': '1',
        'paymentType': '',
        'paymentWay': '2',
        'skus': [
          {
            'quantity': this.quantity,
            'skuId': this.chooseSpu.skuId,
            'spuId': this.productObj.productId,
            'supplyType': this.productObj.supplyType
          }
        ],
        'userAddressId': this.addressValue
      }
      this.paying = true
      addProductorder(order).then(res => {
        if (res.code === 0) {
          const payOrder = {}
          payOrder.payOrderSn = res.data.payOrderSn
          payOrder.payType = this.payType
          productunifiedOrder(payOrder).then(res => {
            if (res.code === 0) {
              this.payCall(payOrder.payOrderSn, res)
            } else {
              this.$message.error('错误')
            }
          })
        }
      })
    },

    payCall(payOrderSn, res) {
      this.paying = false
      if (this.payType === 2) {
        // 支付宝
        // window.location.href = res.data
        window.open(res.data)
        this.payOk(payOrderSn)
      }
      if (this.payType === 1) {
        // 微信
        this.qrcode = res.data.codeUrl
        this.showQrcode = true

        // 开启轮询支付状态
        this.payOk(payOrderSn)
      }
    },

    // 用户地址
    getUseraddress() {
      getUseraddress().then(res => {
        this.addressValueList = res.data.records
      })
    },

    getchinaregion() {
      getchinaregion().then(res => {
        if (res.code === 0) {
          this.regionOptions = res.data
        } else {
          this.$message.error('获取地区错误')
        }
      })
    },

    handleAdd() {
      this.editAddres = false
      this.dialogVisible = true
    },

    handleEdit(item) {
      this.addAddress = item // 地址对象
      const addreeList = []
      addreeList.push(this.addAddress.provinceName)
      addreeList.push(this.addAddress.cityName)
      addreeList.push(this.addAddress.countyName)
      this.addAddress.diquValue = addreeList
      this.editAddres = true
      this.dialogVisible = true
    },

    delUseraddress(id) {
      delUseraddress(id).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getUseraddress()
        }
      })
    },

    xinz() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const abc = this.addAddress
          abc.countyName = this.addAddress.diquValue[2] ? this.addAddress.diquValue[2] : ''
          abc.cityName = this.addAddress.diquValue[1] ? this.addAddress.diquValue[1] : ''
          abc.provinceName = this.addAddress.diquValue[0] ? this.addAddress.diquValue[0] : ''

          // 如果编辑
          if (this.editAddres) {
            // 编辑
            editUserAddress(abc).then(res => {
              if (res.code === 0) {
                this.$message.success('新增成功')
                this.getUseraddress()
                this.dialogVisible = false
                this.addAddress = {}
              } else {
                this.$message.error('编辑错误')
              }
            })
          } else {
            // 新增
            addUserAddress(abc).then(res => {
              if (res.code === 0) {
                this.$message.success('新增成功')
                this.getUseraddress()
                this.dialogVisible = false
                this.addAddress = {}
              } else {
                this.$message.error('新增错误')
              }
            })
          }
        }
      })
    },

    // 关闭弹窗
    handleBeforeClose(done) {
      this.addAddress = {}
      done()
    },

    payOk(payOrderSn) {
      const that = this

      clearInterval(that.maxtimesetIVal) // 清空倒计时
      clearInterval(that.roundRobinPay) // 清空循环查询

      // 最大时间 开始计时分钟
      that.maxtimesetIVal = setInterval(() => {
        --that.maxtime
        if (that.maxtime < 0) {
          console.log('停了')
          clearInterval(that.maxtimesetIVal) // 清空倒计时
          clearInterval(that.roundRobinPay) // 清空循环查询
        }
      }, 1000)

      // 轮询
      that.roundRobinPay = setInterval(() => {
        roundRobinOrder({ payOrderSn: payOrderSn, payType: this.payType }).then((res) => {
          if (res.code === 0) {
            // 支付完成
            console.log('支付完成')
            // 跳页面
            that.$router.push({ path: '/orderPay/result', query: { type: '1' }})
          } else {
            // 支付未完成
            console.log('支付未完成')
          }
        })
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .main{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .ssss{
    width: 100%;
    max-width: 1400px;
  }
    // 总布局，分上下
  .content{
    border-radius: 6px;
    margin: 50px 10% 30px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .image-s {
  background-color: #fff;
  width: 370px;
  height: 370px;
  position: relative;
  margin-right: 20px;
}
  .image-i {
    object-fit: contain;
    object-position: center;
    height: 100%;
    z-index: 100;
  }

  // 内容
  .content-right{
    width: 0;
    flex: 1;
    text-align: left;
  }
  .title{
    vertical-align: middle;
    color: #242833;
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price{
    display: flex;
    align-items: center;
    color: #363d4d;
    font-weight: 600;
    font-size: 16px;
    padding: 27px 0;
  }

  .line{
    display: flex;
    align-items: center;
    // margin-top: 88px;
    padding-top: 20px;
    border-top: 4px solid #f5f5f5;
  }

  .sku-group{
    width: 90%;
    display: flex;
    flex-flow: wrap;
  }
  .sku{
    padding: 4px;
    box-shadow: inset 0 0 0 1px #ccc;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;
    &-images{
      background-color: #fff;
      width: 50px;
      height: 50px;
      position: relative;
    }
    &-imagei{
      object-fit: contain;
      object-position: center;
      height: 100%;
      z-index: 100;
    }
  }
  .chooseSku{
    box-shadow: inset 0 0 0 2px rgb(250, 98, 71);
  }

  .priduct-num{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .buy-btn{
    border: red;
    padding: 0 24px;
    height: 44px;
    min-width: 165px;
    background-image: linear-gradient(
    213deg,#ff7b48 7%,#f73c22 92%);
  }

  // end

  .adress-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .adress{
    max-height: 200px;
    overflow-y: scroll;
  }

  .address-item{
    display: flex;
    align-items: center;
  }

  .pay{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    // 面包屑
  .breadmenu{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    cursor: pointer;
    border-top: #eaedf1 solid 1px;
    width: 100%;
    text-align: left;
    padding: 8px;
    background: #fff;
  }

  .attributes{
    display: flex;
    margin-bottom: 8px;
  }

</style>
